import type { Extension, ForgeUICustomContentExtensionType } from '../types';

export const getAppId = (app: Extension): string | undefined => {
	if (!app?.id) {
		return;
	}

	const [, id] = app.id.split('/');
	return id;
};

export const getModuleKey = (module: Extension): string | undefined => {
	if (!module.id) {
		return;
	}

	const parts = module.id.split('/');
	return parts[parts.length - 1];
};

export const getCustomContentType = (app: ForgeUICustomContentExtensionType): string => {
	return `forge:${getAppId(app)}:${app.environmentId}:${getModuleKey(app)}`;
};

export const getForgeAppsFromForgeData = (forgeData): Extension[] => {
	const extensionContext = forgeData?.extensionContexts?.[0];

	if (!extensionContext) {
		return [];
	}
	if (extensionContext.hasOwnProperty('extensions')) {
		return extensionContext.extensions;
	}
	if (extensionContext.hasOwnProperty('extensionsByType')) {
		return extensionContext.extensionsByType;
	}
	return [];
};
